import React, { useContext, useState, useEffect, useRef } from "react";

import {
  Column,
  Row,
  Utilities,
  ZeCopyright,
  NextButton,
  PageContext,
  DataServiceContext,
  ScienceLogicLogo,
} from "athena-next-ui-lib";

import styles from "./sign-in.module.scss";

import { UserMgmtUtils } from "/_utilities";
import { DialogForgotPassword, PasswordReset } from "/components-biz";

const STEPS = {
  initial: "step-initial",
  email: "step-email",
  inputPassword: "step-pwd",
  resetPassword: "step-reset-password",
  signinSuccess: "step-signin-success",
  signinFailure: "step-signin-failure",
};

const SIGN_IN_TYPE = {
  VALID_INVITE_LINK: "valid-invite-link",
  INVALID_INVITE_LINK: "invalid-invite-link",
  NO_PARAMS: "no-params",
  SAML: "saml",
};

const SignIn = (props) => {
  const pageContext = useContext(PageContext);
  const { env, router } = useContext(PageContext);
  const dataServiceContext = useContext(DataServiceContext);

  const [email, setEmail] = useState(process.env.NEXT_PUBLIC_USER_NAME || "");
  const [password, setPassword] = useState(
    process.env.NEXT_PUBLIC_USER_PASSWORD || ""
  );
  const [currentStepId, setCurrentStepId] = useState(STEPS.initial);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [signInType, setSignInType] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [signInParams, setSignInParams] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [isPasswordResetValid, setIsPasswordResetValid] = useState(false);
  const [focusElement, setFocusElement] = useState(null);

  const DOM_email = useRef();
  const DOM_password = useRef();

  const passwordReset = useRef();
  const forgotPassword = useRef();

  useEffect(() => {
    if (currentStepId) {
      const element = document.getElementById(currentStepId);
      if (element?.scrollIntoView) {
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [currentStepId]);

  useEffect(() => {
    if (focusElement?.current) {
      setTimeout(() => focusElement.current.focus(), 500);
    }
  }, [focusElement]);

  useEffect(async () => {
    if (!signInType) return;

    if (signInType == SIGN_IN_TYPE.VALID_INVITE_LINK) {
      await processCredentialsFromURL();
    } else if (signInType == SIGN_IN_TYPE.INVALID_INVITE_LINK) {
      checkForAuthError();
      navigateToStep(STEPS.resetPassword);
    } else {
      let userIsAlreadySignedIn = false;
      userIsAlreadySignedIn = await dataServiceContext.isValidSession();

      if (userIsAlreadySignedIn) {
        return enterApp();
      } else if (signInType == SIGN_IN_TYPE.SAML) {
        await processSAMLFromURL();
      } else {
        //NO_PARAMS signInType
        checkForAuthError();
        navigateToStep(STEPS.email, DOM_email);
      }
    }
  }, [signInType]);

  useEffect(() => {
    determineSignInTypeFromURL();
    /*

            1. check if we have credentials passed on URL >> attempt sign in
                2. if attempt succeeds >> initializeSession
                3. if forceChangePassword = true >> reset password
                4. if forceChangePassword = false >> enterApp
            5. if we don't have credentials passed on the URL >> initializeSession
                6. if initialized >> enterApp
                7. if not initialized >> begin sign in flow (do nothing else)

         */
  }, []);

  // https://echo.qa.zebrium.com/auth/sign-in?AA0aBykVHAoBExdaUl1e&TF89OA1EQFIpXA%3D%3D

  const determineSignInTypeFromURL = () => {
    const params = Object?.keys(router.query);
    const customerInDeploymentParam = pageContext?.env?.SIGNIN_CUSTOMER || "";

    if (params.length == 0 && customerInDeploymentParam?.length == 0) {
      setSignInType(SIGN_IN_TYPE.NO_PARAMS);
    } else if (router.query.customer && router.query.customer.length > 0) {
      //customer is in the url or in deployment params
      setSignInType(SIGN_IN_TYPE.SAML);
      setCustomer(router.query.customer);
    } else if (customerInDeploymentParam?.length > 0) {
      //customer is in the url or in deployment params
      setSignInType(SIGN_IN_TYPE.SAML);
      setCustomer(customerInDeploymentParam);
    } else if (
      params.length === 2 &&
      router.query[params[0]] === "" &&
      router.query[params[1]] === ""
    ) {
      // we appear to have encoded username/pwd combo on the sign-in URL
      try {
        const decodedUsername = UserMgmtUtils.decodeParam(params[0]);
        const decodedPwd = UserMgmtUtils.decodeParam(params[1]);

        setCurrentPassword(decodedPwd);
        setSignInType(SIGN_IN_TYPE.VALID_INVITE_LINK);
        setSignInParams({
          user_name: decodedUsername,
          password: decodedPwd,
        });
      } catch (err) {
        setSignInType(SIGN_IN_TYPE.INVALID_INVITE_LINK);
        setErrorMessage(
          "Invalid invite link, please contact your system administrator."
        );
      }
    }
  };

  const processSAMLFromURL = async () => {
    return Promise.resolve()
      .then(() => setProcessing(true))
      .then(() =>
        dataServiceContext.fetch(
          "session/create/login",
          { customer: customer },
          true //setting isUnauthenticatedCall to true to prevent redirect to sign in page
        )
      )
      .then((out) => {
        if (out?.response && out.response.code === 200) {
          const samlRedirect = out.data[0].saml_redirect;
          window.open(samlRedirect, "_self");
        } else {
          //something wrong
          console.log("create/login call failed:" + out.response.message);
          //credential is invalid, invite link might have been consumed

          //suppress Muyst supply a password error
          if (out?.response) {
            if (out.response.code !== 409) {
              dataServiceContext.setLocalStorage("authError", out.response);
            }
          }

          if (out?.response?.code !== 409) {
            checkForAuthError();
          }

          //take user to email login page
          return navigateToStep(STEPS.email);
        }
      })
      .finally(() => setProcessing(false));
  };

  const processCredentialsFromURL = async () => {
    // debug code
    //return this.navigateToStep(STEPS.resetPassword);

    //first login with credentials decoded from url
    let response = await dataServiceContext.fetch(
      "session/create/login",
      signInParams
    );

    const isAuthenticated = response && response.response.code == 200;

    if (isAuthenticated) {
      //credential is valid
      const isValidFlag = await dataServiceContext.isValidSession(); //initialize session
      if (isValidFlag) {
        const systemRequiresUserToResetPassword =
          dataServiceContext.isUserRequiredToResetPassword();
        if (systemRequiresUserToResetPassword) {
          passwordReset.current.setAccount(dataServiceContext.getUserProfile());
          passwordReset.current.setFocus();
          return navigateToStep(STEPS.resetPassword);
        } else {
          return navigateToStep(STEPS.email);
        }
      } else {
        //this should never happen, alert if does
        alert("is authenticated but session is invalid");
      }
    } else {
      //credential is invalid, invite link might have been consumed
      if (response?.response) {
        dataServiceContext.setLocalStorage("authError", response.response);
      }

      checkForAuthError();

      //link has been consumed, redirect to email login page
      return navigateToStep(STEPS.email);
    }
  };

  const checkForAuthError = () => {
    const authError = dataServiceContext.getLocalStorage("authError");

    if (authError) {
      setErrorMessage(authError.message);
      dataServiceContext.clearLocalStorage();
    }
  };

  const submitEmailAndPassword = () => {
    const USER_DISABLED = 409;
    const VALID_CREDENTIAL = 200;

    const payload = {
      user_name: email,
      password: password,
    };

    return Promise.resolve()
      .then(() => setProcessing(true))
      .then(() =>
        dataServiceContext.fetch(
          "session/create/login",
          payload,
          true //setting isUnauthenticatedCall to true to prevent redirect to sign in page
        )
      )
      .then((out) => {
        if (out && out.response.code === VALID_CREDENTIAL) {
          const userPrefs = out?.data?.[1]?.data || {};
          if (!userPrefs.theme) {
            userPrefs.theme = "darkTheme";
          }
          return Promise.resolve()
            .then(() =>
              Utilities.LocalCache.setUserPreferences({ ...userPrefs })
            )
            .then(() => dataServiceContext.getProfile())
            .then(() => dataServiceContext.getUserProfile())
            .then((profile) => Utilities.FullStory.identifyUser(profile))
            .then(() => enterApp());
        } else if (out?.response?.code === USER_DISABLED) {
          //login failed
          //invalid user/pwd will return 401 which will set state.errorMessage
          //receiving 409 when user is disabled
          dataServiceContext.setLocalStorage("authError", out.response);
          setErrorMessage(out.response.message);
          checkForAuthError();
          return navigateToStep(STEPS.email);
        } else {
          setErrorMessage(out.response.message);
          return checkForAuthError();
        }
      })
      .finally(() => setProcessing(false));
  };

  const submitNewPassword = () => {
    const newPassword = passwordReset.current.getNewPassword();

    const payload = {
      current_password: currentPassword,
      password: newPassword,
    };

    return Promise.resolve()
      .then(() => setProcessing(true))
      .then(() => dataServiceContext.fetch("profile/update", payload))
      .then((out) => {
        if (out && out?.response?.code === 200) {
          //update profile in localStorage
          return Promise.resolve()
            .then(() => dataServiceContext.getProfile())
            .then(() => dataServiceContext.getUserProfile())
            .then((profile) => Utilities.FullStory.identifyUser(profile))
            .then(() => enterApp());
        } else {
          //reset password failed
          return checkForAuthError();
        }
      })
      .then(() => setProcessing(false));
  };

  const submitEmail = () => {
    let payload = { user_name: email };

    return Promise.resolve()
      .then(() => setProcessing(true))
      .then(() => {
        return dataServiceContext.fetch("session/create/login", payload);
      })
      .then((out) => {
        if (out.response.code === 200) {
          return Promise.resolve().then(() => {
            if (out.data && out.data.length > 0 && out.data[0].saml_redirect) {
              const samlRedirect = out.data[0].saml_redirect;
              window.open(samlRedirect, "_self");
              return true;
            } else {
              return Promise.resolve()
                .then(() => dataServiceContext.getProfile())
                .then(() => dataServiceContext.getUserProfile())
                .then((profile) => Utilities.FullStory.identifyUser(profile))
                .then(() => enterApp());
            }
          });
        } else if (out.response.code === 409) {
          //need to get password
          setErrorMessage("");

          setTimeout(() => navigateToStep("step-pwd", DOM_password), 250);
        } else {
          setErrorMessage(out.response.message);
          setTimeout(() => navigateToStep("step-email", DOM_email), 250);
        }
        return true;
      })
      .finally(() => setProcessing(false));
  };

  const onKeyDownHandler = (evt) => {
    if (evt.keyCode === 9 || (evt.shiftKey && evt.keyCode === 9)) {
      //ignore tab or shift-tab
      evt.preventDefault();
    }
  };

  const enterApp = () => {
    const { env } = pageContext;

    const prefix = env.INGRESS_PREFIX;

    //need to check if user has a redirect link
    const redirectLink =
      dataServiceContext.getLocalStorage("redirectUrlAfterSignin") || null;
    if (redirectLink) {
      dataServiceContext.removeLocalStorage("redirectUrlAfterSignin");
      router.push(redirectLink);
      return;
    }

    return Promise.resolve()
      .then(() => {
        //check to see if userPrefs is in localStorage, if not
        //need to set theme in localStorage
        if( !Utilities.LocalCache.getUserPreferences() ) {
        //the following code will be skipped if user login through
        //email/password 
        const userProfile = dataServiceContext.getUserProfile();
          const userPrefs = userProfile?.data || {};
          if (!userPrefs.theme) {
            userPrefs.theme = "darkTheme";
          }
          Utilities.LocalCache.setUserPreferences({ ...userPrefs });
        }
        return;
      })
      .then(() => dataServiceContext.setLocalStorage("justSignedIn", true))
      .then(() => setProcessing(true))
      .then(() => dataServiceContext.fetch("ingest/read/state", {}))
      .then((ingestState) => {
        if (ingestState.response.code === 403) {
          dataServiceContext.redirectToLandingPage();
        } else if (ingestState.response.code !== 200) {
          router.push(prefix + "/auth/sign-up/");
        } else if (
          ingestState.data[0].state !== "noData" ||
          !dataServiceContext.AccessManifest(
            "canFullyEditFeature",
            "log-collector"
          )
        ) {
          dataServiceContext.redirectToLandingPage();
        } else {
          //only when user have access to "integration" with edit role
          dataServiceContext.redirectToGettingStarted();
        }
        return true;
      });
  };

  const startOver = () => {
    navigateToStep("step-email");
  };

  const navigateToStep = (name, setFocusOnRef) => {
    setCurrentStepId(name);
    setFocusElement(setFocusOnRef);
  };

  const deriveStepCSS = (stepId) => {
    return currentStepId === stepId
      ? `${styles.step} ${styles.selected}`
      : `${styles.step}`;
  };

  const drawPageTitle = () => {
    const { env } = pageContext;
    return (
      <div className={styles.title}>
        <ScienceLogicLogo
          width="200"
          height="50"
          theme="darkTheme"
        />
      </div>
    );
  };

  const drawStepEmail = () => {
    const stepId = STEPS.email;
    const css = deriveStepCSS(stepId);

    return (
      <div id={stepId} className={css}>
        <Row>
          <div className={styles.stepTitle} style={{ padding: "30px" }}>
            <span>Sign In</span>
            <span className={styles.subtitle}>enter email</span>
          </div>
        </Row>

        <Row>
          <div className={styles.stepMain}>
            <input
              type={"email"}
              placeholder=""
              ref={DOM_email}
              value={email}
              onKeyDown={(evt) => onKeyDownHandler(evt)}
              onKeyUp={(evt) => evt.key === "Enter" && submitEmail()}
              onChange={(evt) => setEmail(evt.currentTarget.value)}
            />
          </div>
        </Row>

        <Row>
          <div className={styles.stepMain} style={{ padding: "30px" }}>
            <NextButton processing={processing} action={submitEmail} />
          </div>
        </Row>

        {env.PRODUCT_TYPE.toLowerCase() === "saas" && (
          <Row>
            <div className={styles.stepMain}>
              <p>
                No Account?{" "}
                <a
                  className="link"
                  href={`https://sciencelogic.com/request-a-free-trial`}
                >
                  Sign Up
                </a>
              </p>
            </div>
          </Row>
        )}
      </div>
    );
  };

  const forgotPasswordClickHandler = () => {
    forgotPassword.current.showDialog();
    forgotPassword.current.sendResetPasswordLink(email);
  };

  const drawStepPassword = () => {
    const stepId = STEPS.inputPassword;
    const css = deriveStepCSS(stepId);

    return (
      <div id={stepId} className={css}>
        <Column>
          <Row>
            <div className={styles.stepTitle} style={{ padding: "30px" }}>
              <span>Sign In</span>
              <span className={styles.subtitle}>enter password</span>
            </div>
          </Row>

          <Row>
            <input
              type={"password"}
              placeholder=""
              ref={DOM_password}
              value={password}
              onKeyDown={(evt) => onKeyDownHandler(evt)}
              onKeyUp={(evt) => evt.key === "Enter" && submitEmailAndPassword()}
              onChange={(evt) => setPassword(evt.currentTarget.value)}
            />
          </Row>
          {env.PRODUCT_TYPE.toLowerCase() === "saas" && (
            <Row>
              <div className={styles.stepMain} style={{ marginTop: "10px" }}>
                &nbsp;{" "}
                <a className="link" onClick={forgotPasswordClickHandler}>
                  Forgot password?
                </a>
              </div>
            </Row>
          )}

          <Row>
            <div className={styles.stepMain} style={{ padding: "30px" }}>
              <NextButton
                undo={true}
                disabled={processing}
                action={() => {
                  if (!processing) {
                    dataServiceContext.clearLocalStorage();
                    setErrorMessage("");
                    navigateToStep(STEPS.email);
                  }
                }}
              />
              &nbsp;&nbsp;
              <NextButton
                processing={processing}
                action={submitEmailAndPassword}
              />
            </div>
          </Row>
        </Column>
      </div>
    );
  };

  const drawStepSignInSuccess = () => {
    const stepId = STEPS.signinSuccess;
    const css = deriveStepCSS(stepId);

    return (
      <div id={stepId} className={css}>
        <h1>Success</h1>
        <NextButton processing={processing} action={enterApp} />
      </div>
    );
  };

  const drawStepSignInFailure = () => {
    const stepId = STEPS.signinFailure;
    const css = deriveStepCSS(stepId);

    return (
      <div id={stepId} className={css}>
        <h1>Failure</h1>
        <button onClick={() => startOver()}>Try Again</button>
      </div>
    );
  };

  const drawStepInitial = () => {
    const stepId = STEPS.initial;
    const css = deriveStepCSS(stepId);

    return <div id={stepId} className={css}></div>;
  };

  const drawStepPasswordReset = () => {
    const invalidInviteLink = signInType == SIGN_IN_TYPE.INVALID_INVITE_LINK;
    const nextButtonDisabled = processing || !isPasswordResetValid;

    const stepId = STEPS.resetPassword;
    return (
      <PasswordReset
        ref={passwordReset}
        stepId={stepId}
        visible={!invalidInviteLink}
        className={deriveStepCSS(stepId)}
        passwordResetValidationCallback={(isValid) =>
          setIsPasswordResetValid(isValid)
        }
        onSubmit={submitNewPassword}
      >
        {!invalidInviteLink && (
          <NextButton
            disabled={nextButtonDisabled}
            processing={processing}
            action={submitNewPassword}
          />
        )}
      </PasswordReset>
    );
  };

  if (signInType === SIGN_IN_TYPE.SAML) {
    return <div data-testid="saml-signin-url" />;
  }

  return (
    <div className={"auth-bg"}>
      <div className={styles.page}>
        {drawPageTitle()}
        <div className={styles.copyrightContainer}>
          {/*<div className={styles.logoOverlay}>*/}
          {/*    <div className={styles.logo}/>*/}
          {/*</div>*/}
          <div className={styles.errorOverlay}>{errorMessage}</div>
          <div className={styles.stepsContainer}>
            <div className={styles.steps}>
              {drawStepInitial()}

              {drawStepEmail()}

              {drawStepPassword()}

              {drawStepSignInSuccess()}

              {drawStepSignInFailure()}

              {drawStepPasswordReset()}

              <DialogForgotPassword ref={forgotPassword} email={email} />
            </div>
          </div>
        </div>
        <div style={{ padding: "10px" }}>
          <ZeCopyright />
        </div>
      </div>
    </div>
  );
};
SignIn.getInitialProps = async (ctx) => {
  return {};
};
export default SignIn;
